<template>
  <div class="relative w-full">
    <button
        @click="() => (showList = !showList)"
        class="relative w-full rounded-md border border-green py-2 pl-3 pr-3 text-left sm:text-sm block bg-white" style="border-color: #d6a686;"
        :class="[this.$props.fill ? 'bg-green' : '']"
    >
      <span
          class="block truncate"
          :class="[
          this.$props.fill
            ? 'text-white text-center'
            : selected
            ? 'text-black'
            : 'text-gray-400',
        ]"
      >{{
          selected ? `${selected?.name} (${selected?.price} XAF)` : placeholder
        }}
      </span>
      <GiftComponent
          class="inset-y-0 absolute right-10 flex items-center"
          v-if="selected?.promo"
      />
      <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
          :class="[this.$props.fill ? 'text-white' : 'text-green']"
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="{1.5}"
            stroke="currentColor"
            class="w-6 h-6"
            :class="[showList ? 'transform rotate-180' : '']"
        >
          <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </span>
    </button>

    <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
      <div
          v-if="showList"
          class="absolute max-h-80 w-full overflow-hidden rounded-md bg-white text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border border-green"
          style="bottom: 40px; background: #fcfcfc"
      >
        <ul>
          <li
              v-for="(subscription, key) in subscriptions"
              :key="subscription.name"
              :value="subscription.id"
              as="template"
              :class="[
              subscription.id === selected?.id
                ? 'bg-yellow-100 bg-opacity-75 text-black'
                : 'text-gray-900 hover:bg-gray-300 hover:bg-opacity-10',
              'relative cursor-default select-none py-1 pl-2 pr-1 flex justify-between items-center',
              key > 0 ? 'border-t border-gray-400' : '',
            ]"
              :style="[
              {
                background:
                  subscription.is_best && subscription.id !== selected?.id
                    ? '#FDFBE1'
                    : '',
                border: subscription.is_best ? '1px solid #F4C286' : '',
              },
            ]"
              class="cursor-pointer text-left relative"
              @click="handleChangeSubcription(subscription)"
          >
            <div>
              <span class="font-medium block"
              >
                <span v-if="subscription.promo" style="font-size: 14px;" v-html="subscription.promo_text_style"></span>
                <template v-else>{{ subscription.name }}</template>
                ({{ subscription?.price }} XAF)</span
              >
              <span style="font-size: 12px"
              >{{ subscription.pu }} XAF / Magazine</span
              >
              <!-- <span
                v-if="subscription.id === selected?.id"
                class="absolute inset-y-0 left-0 flex items-center pl-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
              </span> -->
            </div>
            <div
                class="bg-green flex justify-center items-center rounded-md py-1 px-1 h-12 gap-x-1 w-20"
                v-if="subscription.promo"
            >
              <div class="text-center flex flex-col">
                <span style="color:#f0cc81" class="block text-md font-extrabold">{{
                    subscription.nn + subscription.promo
                  }}</span>
                <span class="text-white -mt-2" style="font-size: 10px;">Jours</span>
              </div>
              <GiftComponent/>
            </div>
            <BestBadgeComponent v-else-if="subscription.is_best"/>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
import BestBadgeComponent from "@/components/BestBadgeComponent";
import GiftComponent from "@/components/Icons/GiftComponent";

export default {
  name: "ListBoxComponent",
  props: ["subscriptions", "value", "placeholder", "fill"],
  emits: ["update:value"],
  components: {
    BestBadgeComponent,
    GiftComponent,
  },
  data() {
    return {
      selected: null,
      showList: false,
      placeholder: this.$props.placeholder ?? "Sélectionner votre forfait",
    };
  },
  methods: {
    handleChangeSubcription(subscription) {
      this.selected = subscription;
      this.showList = false;
    },
  },
  mounted() {
    this.selected = this.value;
  },
  updated() {
    this.$emit("update:value", this.selected);
  },
};
</script>