<template>
  <button class="text-white p-2 sm:px-4 sm:py-3 bg-green rounded-md w-full flex items-center justify-center text-center">
    <!--     <span class="mr-2">Acheter ce numéro ( {{ price }} XAF )</span>
 -->
    <span class="mr-2">Procéder au paiement</span>
    <PayIconComponent />
  </button>
</template>

<script>
import PayIconComponent from "./Icons/PayIconComponent.vue";
export default {
  name: "ButtonPaymentComponent",
  components: {
    PayIconComponent,
  },
  props: {
    price: {
      type: Number,
      default: null,
    },
  },
};
</script>