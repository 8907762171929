<template>
  <transition
    v-if="this.img"
    enterActiveClass="transition duration-500 ease-in"
    enterFromClass="opacity-0"
    enterToClass="opacity-100"
    leave-active-class="transition duration-500 ease-in"
    leave-to-class="opacity-0"
  >
    <div
      v-if="this.modalIsOpen"
      className="z-30 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div
        class="relative"
        style="min-height: 400px"
        :class="[
          !this.imgIsloaded ? 'animate-pulse bg-green bg-opacity-40 w-10/12 md:w-80' : '',
        ]"
      >
        <button
          class="bg-red-500 rounded-full p-1 absolute -top-5 right-1 text-white z-50"
          @click="handleCloseModal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-6 h-6"
          >
            <path
              d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
            />
          </svg>
        </button>
        <img
          v-if="this.imgIsloaded"
          @contextmenu="
            (e) => {
              e.preventDefault();
              return false;
            }
          "
          :src="this.img"
          alt="Nyanga Magazine"
          class="w-11/12 max-w-md mx-auto rounded-md"
        />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalComponent",
  props: ["modalIsOpen", "img"],
  data() {
    return {
      imgIsloaded: false,
    };
  },
  updated() {
    if (!this.imgIsloaded) {
      const oldThis = this;
      const image = new Image();
      image.src = oldThis.img;
      image.addEventListener("load", (e) => {
        oldThis.imgIsloaded = true;
      });
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit("update:modalIsOpen", false);
    },
  },
};
</script>
