<template>
  <div class="relative flex items-center">
    <!-- style="background-color: #545454 ;" -->
    <input
        id="phoneNumber"
        v-model="value"
        v-on:input="resetError()"
        type="number"
        style="outline: none; border: 1px solid #d6a686;"
        class="bg-white text-center form-control block w-full mx-auto px-3 py-2.5 text-base font-normal text-gray-700 border-b border-solid border-gray-300 rounded m-0 focus:outline-none"
        placeholder="Numéro de téléphone"
    />
    <label class="right-2 absolute cursor-pointer bg-white px-2 py-1" for="phoneNumber">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
      >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
        />
      </svg>
    </label>
  </div>
</template>

<script>
export default {
  name: "InputNumberComponent",
  props: ["value"],
  emits: ["update:value", "update:error"],
  data() {
    return {
      value: this.$props.value
    }
  },
  methods: {
    resetError() {
      this.$emit("update:error", false);
    },
  },
  updated() {
    this.$emit("update:value", this.value);
  },
};
</script>
<style>
input#phoneNumber {
  border-bottom: 2px solid #d6a686;
}
</style>